import React, { useRef } from 'react';
import useTextRevealAnimation from '../../../hooks/useTextRevealAnimation';

import { ReactComponent as Wordmark } from '../../../assets/logos/Mekteb_Wordmark.svg';
import './ComingSoonHeader.scss';

const ComingSoonHeader = () => {
    const headerRef = useTextRevealAnimation('.u-transform-down');


    return (
        <header className="coming-soon-header" ref={headerRef}>
            <div className="coming-soon-header__inner">

                <div className="coming-soon-header__wordmark u-overflow-hidden">
                    <div className="coming-soon-header__wordmark-container u-transform-down">
                        <Wordmark />
                    </div>
                </div>

                <div className="coming-soon-header__copyright u-overflow-hidden">
                    <span className="u-transform-down">© 2024</span>
                </div>

            </div>
        </header>
    );
}

export default ComingSoonHeader;
