import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ComingSoonPage from './pages/ComingSoon/ComingSoon';
import HomePage from './pages/Home/Home';
import InfoPage from './pages/Info/Info';
import WorksPage from './pages/Works/Works';
import ContactPage from './pages/Contact/Contact';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ComingSoonPage />} />
        <Route path="*" element={<ComingSoonPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

