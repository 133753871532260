import { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

const useTextRevealAnimation = (targetsClass) => {
    const ref = useRef(null);

    useGSAP(() => {
        if (ref.current) {
            gsap.from(ref.current.querySelectorAll(targetsClass), {
                y: "-100%",
                duration: 2,
                ease: "power3.inOut",
            });
        }
    }, { scope: ref });

    return ref;
};

export default useTextRevealAnimation;
