import React from 'react';
import * as THREE from 'three';
import { useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Image, Environment, ScrollControls, useScroll, useTexture } from '@react-three/drei'
import { easing } from 'maath'
import './utils/ThreeDExtensions.js';
import './ThreeDcarousel.scss';

const ThreeDcarousel = () => {
    return (
        <Canvas
            camera={{ position: [0, 0, 100], fov: 15 }}
            gl={{
                alpha: true,
                toneMapping: THREE.ACESFilmicToneMapping,
                antialias: true,
            }}>

            {/* <fog attach="fog" args={['#a79', 8.5, 12]} /> */}
            <ScrollControls horizontal={true} pages={4} infinite>
                <Rig rotation={[0, 0, 0]}>
                    <Carousel />
                </Rig>
            </ScrollControls>
            {/* <Environment preset="dawn" background="false" blur={0.5} /> */}
        </Canvas>
    );

    function Rig(props) {
        const ref = useRef()
        const scroll = useScroll()
        const initialZRotation = 0.2; // Adjust this value to get the desired skew angle

        useFrame((state, delta) => {
            const yRotation = -scroll.offset * (Math.PI * 2); // Rotate contents on Y-axis based on scroll

            // Reset rotation and apply the initial Z-axis skew
            ref.current.rotation.set(0, 0, initialZRotation);

            // Then apply Y-axis rotation
            ref.current.rotateY(yRotation);

            state.events.update(); // Raycasts every frame rather than on pointer-move
            easing.damp3(state.camera.position, [-state.pointer.x * 2, state.pointer.y + 1.5, 10], 0.3, delta); // Move camera while hovering around.
            state.camera.lookAt(0, 0, 0); // Look at center
        })

        return <group ref={ref} {...props} />
    }


    function Carousel({ radius = 1.2, count = 8 }) { // Size of circle carousel and number of cards
        return Array.from({ length: count }, (_, i) => (
            <Card
                key={i}
                url={`/coming_soon_carousel_image_${Math.floor(i % 10) + 1}.jpg`} // Path to image for carousel
                position={[Math.sin((i / count) * Math.PI * 2) * radius, 0, Math.cos((i / count) * Math.PI * 2) * radius]}
                rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
            />
        ))
    }

    function Card({ url, ...props }) {
        const texture = useTexture(url);
        texture.colorSpace = THREE.SRGBColorSpace; // Ensure the texture's color space is sRGB
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;

        const ref = useRef();
        const [hovered, hover] = useState(false);
        const pointerOver = (e) => (e.stopPropagation(), hover(true));
        const pointerOut = () => hover(false);

        useFrame((state, delta) => {
            easing.damp(ref.current.material, 'radius', 0.04, 0.04, delta); // Border radius
        });

        return (
            <Image toneMapped={false} ref={ref} url={url} transparent side={THREE.DoubleSide} onPointerOver={pointerOver} onPointerOut={pointerOut} {...props}>
                <bentPlaneGeometry args={[0.1, 0.84375, 1.5, 20, 20]} />
            </Image>
        );
    }

}

export default ThreeDcarousel;