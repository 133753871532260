import React from 'react';

import './ComingSoon.scss';

import ComingSoonHeader from '../../components/organisms/ComingSoonHeader/ComingSoonHeader';
import ComingSoonThreeDCarousel from '../../components/organisms/ComingSoonThreeDCarousel/ComingSoonThreeDCarousel';
import ComingSoonFooter from '../../components/organisms/ComingSoonFooter/ComingSoonFooter';


const ComingSoonPage = () => {
    return (

        // Layout toevoegen ?

        <div className="coming-soon-page">
            <div className="coming-soon-page__content">
                <ComingSoonHeader />

                <ComingSoonFooter />
            </div>
        </div>
    );
}

export default ComingSoonPage;
