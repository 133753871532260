import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import App from './App';

import { PrismicProvider } from '@prismicio/react';
import { client } from './services/prismic/prismic';

async function fetchPortfolioGalleryItems() {
  try {
    // Fetch the primary documents
    const portfolioResponse = await client.getAllByType('portfolio_gallery_items');

    // Now for each portfolio item, fetch the related category using the ID
    const portfolioItemsWithCategories = await Promise.all(portfolioResponse.map(async (item) => {
      let category_name = "No Category";

      if (item.data.client_category.id) {
        try {
          const categoryDoc = await client.getByID(item.data.client_category.id);
          category_name = categoryDoc.data.category_name; // Replace 'category_name' with the correct field identifier
        } catch (error) {
          console.error("Error fetching category: ", error);
        }
      }

      // Construct the portfolio item object including the fetched category name
      return {
        ...item.data,
        client_category: category_name
      };
    }));

    // Log the results
    console.log(portfolioItemsWithCategories);
  } catch (error) {
    console.error("Error fetching portfolio gallery items: ", error);
  }
}

fetchPortfolioGalleryItems();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PrismicProvider client={client}>
      <App />
    </PrismicProvider>
  </React.StrictMode>
);
